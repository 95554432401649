import * as React from "react"
import { Helmet } from "react-helmet"
import { Link } from "gatsby"

import { Item } from "../components/Game/Scoreboard"
import { URLConversion } from "../components/Constants"

type Props = {
	pageContext: { questions: [{ averageScore: number; query: string }] }
}

const AnswersIndex = ({ pageContext }: Props) => (
	<>
		<Helmet>
			<title>Google Feud - Answers</title>
		</Helmet>
		<Item
			scoreName={`Answers for ${new Date().toLocaleDateString()}`}
			scoreValue={
				<ul>
					{pageContext.questions.sort().map((question) => {
						const averageScore =
							typeof question.averageScore === "undefined"
								? 0
								: question.averageScore
						return (
							<li key={question.query} className="altList">
								<Link to={URLConversion(question.query)}>
									{`${question.query} (${Math.floor(
										averageScore
									).toLocaleString()})`}
								</Link>
							</li>
						)
					})}
				</ul>
			}
		/>
	</>
)
export default AnswersIndex
