import React from "react"
export const failXTime = 1500
export const numberOfAnswers = 10
export const numberOfGuesses = 4
export const numberOfRounds = 3
export const easyThreshold = 17500
export const numberOfEasyReveals = 2
export const enterCheck = (event : React.KeyboardEvent<HTMLInputElement> | null) =>
	event !== null && event.type === "keydown" && event.key !== "Enter"
// eslint-disable-next-line no-undef
export const logEvent = (Gtag: Gtag.Gtag, eventName: string, eventParams: Gtag.CustomParams) => {
	if (typeof window !== "undefined" && typeof gtag !== "undefined") {
		Gtag('event', eventName, eventParams)
	}
}
